@charset "utf-8"

@import "../../styles/bulma_legacy/functions.sass"
@import "../../styles/bulma_legacy/mixins.sass"
@import "../../styles/variables.sass"






























.help-page
  background-color: $white

  .content

    br
      line-height: 2rem
